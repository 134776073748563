import { MDXProvider } from "@mdx-js/react";
import type { ComponentProps } from "react";

export const MDXComponents: ComponentProps<typeof MDXProvider>["components"] = {
	main: (props) => <main className="space-y-4 px-16 py-12" {...props} />,
	ul: (props) => <ul className="list-inside list-disc" {...props} />,
	ol: (props) => <ol className="list-decimal" {...props} />,
	li: (props) => <li className="mb-2 ml-3 last-of-type:mb-0" {...props} />,
	a: (props) => (
		<a className="text-clearSky-500 hover:underline" {...props}>
			{props.children}
		</a>
	),
};
